<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-10">Taşıma Barkod Yazdır</div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CCol md="12">
          </CCol>
        </CCardBody>
        <CCardBody>
          <span><b>Sipariş Numarasını Okutun/Girin</b></span><br/><br/>
          <CForm onsubmit="return false;">
            <CRow>
              <CCol sm="8">
                <CInput
                  type="input"
                  horizontal
                  placeholder="Order Id girin"
                  v-model="orderId"
                />
              </CCol>
              <CCol sm="2">
                <CButton type="submit" color="success" @click="shipmentAwbBarcodePrint(orderId)">Sorgula
                </CButton>
              </CCol>
            </CRow>
          </CForm>

          <span><small><i>Bu ekran: Kargo firmalarının tarafımıza sağlamış olduğu takip bilgilerini barkod olarak yazılması içindir.</i></small></span>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="operationEvent"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      size="xl"
      ref="confirm"
      :isFrame="true"
    />
  </CRow>
</template>

<script>

import ConfirmModal from '../components/confirmModal'
export default {
  name: 'ShipmentAwbBarcode',
  data() {
    return {
      orderId:'',
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'confirm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
    }
  },

  components: {ConfirmModal},
  computed:{
    loading: function(){
      return this.$store.getters.orderLoading
    }
  },
  methods: {
    async operationEvent(item, action) {
      var a = window.open('', '', 'height=500, width=500');
      a.document.write(this.$store.getters.orderJSP);
      setTimeout(() => {
        a.print() 
        a.close();
      }, 1000);
      return
    },
    async openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    async shipmentAwbBarcodePrint(orderId) {
      if (orderId === "") {
        const toast = {
          msg:  " Order ID girilmedi/barkod okutulmadı. Tekrar kontrol edin.",
          header: 'Uyarı',
          color: 'warning'
        }
        vm.$store.commit('showToast', toast)
        return;
      }
      await this.$store.dispatch('order_shippingAwbBarcode', {"orderId": orderId})
      await this.openModalEvent('print', 'confirm', null, 'Taşıma AWB Barkodu Yazdır')
      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
     
    },
  },
}
</script>
<style>
input,
select {
  padding: 0.45em 0.5em;
  font-size: 100%;
  border: 1px solid #ccc;
  width: 100%;
}
</style>
